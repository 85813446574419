import { useMemo, useState, useCallback } from 'react'
import Button from '@components/control/Button'
import { getGtmGroup } from '@components/helper'
import { useUI, Picture, ButtonV2 } from '@components/ui'
import { addQueryParam, atobID } from '@lib/utils/tools'
import { createCheckout } from 'lib/shopify/api/checkout'
import { useAddItem } from 'lib/shopify/api/cart'
import cn from 'classnames'
import { useRouter } from 'next/router'
import styles from './Cta.module.css'
import {
  pageGTMEvent,
  GTMtagsToCollection,
  getAmazonLinkParams,
  pageTwttr,
} from '@lib/utils/thirdparty'
import Decimal from 'decimal.js'
import useCustomer from '@shopify/customer/use-customer'
import { useCodeApply } from 'lib/shopify/api/cart'
import SolixButton from '@components/control/SolixButton'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const Cta = ({
  copy,
  autoApplyCode = true,
  autoOpenSidebar = true,
  autoCode = null,
  product = null,
  products = [],
  traceProduct = null, //大充 bundle 加购场景，需要给 bundle 商品埋点而不是加购的两个 bundle，
  quantity = 1,
  s: gs = {},
  className = '',
  cns = {},
  pageHandle = '',
  componentKey = '',
  buttonTxt = '',
  buttons = [
    { type: 'addToCart', theme: 'primary' },
    { type: 'link', theme: 'secondary' },
  ],
  customAttributes = [],
  buynowGiftLineItems = [],
  disabled = false,
  sku = '',
  cartCustomAttribute = [],
  gtmParam = {},
  solixButton = false,
  solixButtonBorder = false,
  blank = true,
  fridayBlackChg2023 = {},
  combine_variant = [],
  custom_link = null,
  squareBorder = false,
  buyNowBeforeFunc = null,
}) => {
  if (!products?.length) {
    products = [product]
  }
  const s = { ...styles, ...gs }
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const addItem = useAddItem()
  const { openSidebar } = useUI()
  const { data: customer } = useCustomer()
  const codeApply = useCodeApply()
  const { setRelativeLink } = useRelativeLink()

  const regex = /<[^>]*>([^<]+)<\/[^>]*>/g
  const nameMatch = gtmParam?.itemListName
    ? regex.exec(gtmParam?.itemListName)
    : []

  // console.log('customAttributes:', customAttributes)
  const lineItems =
    combine_variant?.length > 0
      ? combine_variant?.map((item) => {
          return {
            // productId: item?.product?.id,
            variantId: item?.variant?.id || String(item?.variantId),
            quantity: item?.quantity || 1,
            customAttributes,
          }
        })
      : products?.map((product) => ({
          variantId: String(product?.variantId),
          quantity: quantity || product?.quantity || 1,
          customAttributes,
        }))

  const appliedCode = useMemo(() => {
    const codes =
      combine_variant?.length > 0
        ? combine_variant?.map((item) => item?.coupon?.title)?.filter(Boolean)
        : products?.map((p) => p?.code)?.filter(Boolean)
    if (!autoApplyCode) return ''
    if (autoCode) {
      return autoCode
    } else if (traceProduct && traceProduct.code) {
      return traceProduct.code
    } else if (codes?.length > 0) {
      return codes
    }
    return ''
  }, [autoCode, traceProduct, products])

  const goToCheckout = useCallback(
    async (data, appliedCode) => {
      // setLoading(true)
      try {
        // 兼容方案：在升级 checkout.liquid 前，无法从cart带多个discount到checkout
        if (data?.lineItems?.length > 0) {
          const checkout = await createCheckout({
            locale: router?.locale,
            lineItems: data?.lineItems,
            customAttributes: data?.customAttributes,
            ...(!!appliedCode && {
              discountCodes: Array.isArray(appliedCode)
                ? appliedCode
                : [appliedCode],
            }),
          })
          const checkoutUrl = checkout?.webUrl
          window.location.assign(checkoutUrl)
        }
      } catch (e) {
        console.log(e)
      }

      setLoading(false)
    },
    [router?.locale]
  )

  const isObjectNotEmpty = (obj) => {
    return JSON.stringify(obj) === '{}'
  }

  const hasAutoApplyCode = () => {
    const chgItem = products?.filter((item) => {
      const tags = item?.tags || []
      return fridayBlackChg2023?.chg_tags?.some((tag) => tags.includes(tag))
    })
    if (isObjectNotEmpty(fridayBlackChg2023) || chgItem.length <= 1) return true
    let curTotal = 0
    let dealsSubtotal = 0
    chgItem?.map((item) => {
      dealsSubtotal += Number(item?.basePrice)
      curTotal += Number(item?.price)
    })
    const discountKeys = Object.keys(fridayBlackChg2023?.discount)
    const lastKey =
      discountKeys.length > 0 ? discountKeys[discountKeys.length - 1] : 0
    const number = Math.min(chgItem?.length, lastKey)
    const discountText = fridayBlackChg2023?.discount[number] || 0.25
    const sale_price =
      (dealsSubtotal * 100 - dealsSubtotal * 100 * discountText) / 100
    // 总分价向上取整后再保留两位小数
    const dealsTotal = Math.ceil(sale_price * 100) / 100
    return curTotal <= dealsTotal
  }

  const buyNow = async () => {
    const data = {
      lineItems: lineItems?.concat(buynowGiftLineItems),
      customAttributes,
    }

    if (typeof buyNowBeforeFunc == 'function') {
      buyNowBeforeFunc({
        setLoading: setLoading,
        goToCheckout: goToCheckout,
        dataLineItems: data,
      })
    } else {
      setLoading(true)
      goToCheckout(data, hasAutoApplyCode() ? appliedCode : [])
    }

    buyNowGTM({
      products: traceProduct ? [traceProduct] : products,
      router,
      info: customer ? 'member' : 'non-member',
      gtmParam,
      combine_variant,
      nameMatch,
      ref,
    })
  }

  const getLink = (l) => {
    let link
    const product = traceProduct ? traceProduct : products[0]
    if (l) {
      l = setRelativeLink({
        link: l,
      })
      const hasParams = l.includes('?')
      if (hasParams) {
        link = `${l}&ref=${ref}`
      } else {
        link = `${l}?variant=${atobID(product?.variantId)}&ref=${ref}`
      }
    } else {
      link = `/${router.locale}/products/${product?.handle}?variant=${atobID(
        product?.variantId
      )}&ref=${ref}`
    }

    return link
  }

  const addToCart = async () => {
    setLoading(true)
    try {
      await addItem(lineItems)
      autoOpenSidebar && openSidebar()
      setLoading(false)
      if (appliedCode && autoApplyCode) {
        setTimeout(() => codeApply({ discountCode: appliedCode }))
      }
    } catch (err) {
      console.log('err:', err)
      setLoading(false)
    }
    addLearnMoreGTM('addToCart')
    addToCartGTM({
      products: traceProduct ? [traceProduct] : products,
      router,
      info: customer ? 'member' : 'non-member',
      gtmParam,
      combine_variant,
      nameMatch,
      ref,
    })
    try {
      obApi && obApi('track', 'Add to cart2')
    } catch (error) {
      console.log('obApi-error', error)
    }
  }

  const addLearnMoreGTM = (type) => {
    if (!type) {
      if (copy?.pageFbTrak) {
        fbq && fbq('trackCustom', `${copy?.pageFbTrak}`)
      }
      if (copy?.pageFbTrack) {
        fbq && fbq('trackCustom', `${copy?.pageFbTrack}`)
      }
    } else {
      fbq && fbq('trackCustom', `${copy?.pageFbTrackAddCart}`)
    }

    // 暂时性埋点，针对黑五页面
    if (copy?.pageFb) {
      if (type) {
        fbq && fbq('trackCustom', 'Solixfamily A to B Button')
      } else {
        if (product?.metafields?.pageType?.pageType != 'hes') {
          fbq && fbq('trackCustom', 'PPS Learn More')
        } else {
          fbq && fbq('trackCustom', 'Solixfamily A to B Button')
        }
      }
    }
    if (copy?.weihnachtenFb) {
      if (product?.metafields?.pageType?.pageType != 'hes') {
        fbq && fbq('trackCustom', 'PPS Learn More')
      } else {
        fbq && fbq('trackCustom', 'Solix Learn More')
      }
    }
    // 并机
    if (copy?.parallelOperation) {
      pageGTMEvent({ event_parameters: null })
      pageGTMEvent({
        event: 'ga4Event',
        event_name: 'lp_button',
        event_parameters: {
          page_group: 'Product Detail Page_' + pageHandle,
          button_name: products?.[0]?.sku, //读取跳转页的SKU
          position: 'cross_buy',
        },
      })
      pageGTMEvent({
        event: 'uaEvent', // Trigger
        eventCategory: 'Product Detail Page_' + pageHandle, //该listing页的SKU
        eventAction: 'cross_buy',
        eventLabel: products?.[0]?.sku, //读取跳转页的SKU
      })
    }
  }

  const ref =
    copy?.ref || (componentKey ? `${pageHandle}-${componentKey}` : pageHandle)

  const handleBuried = (current_sku) => {
    if (typeof fbq !== 'undefined') {
      fbq('track', 'BuyAtAmazon', {
        content_ids: [].concat(sku || current_sku),
      })
      copy?.extraAmzTrak && fbq('trackCustom', copy?.extraAmzTrak)
    }
    pageTwttr({ event: 'o8e4y', tw_sale_amount: 0, tw_order_quantity: 0 })
  }

  return (
    <div
      className={cn(s.buttons, className, 'cta-buttons', {
        [s.vertical]: copy?.layout === 'vertical',
        [s.fullWidth]: copy?.fullWidth || buttons?.length === 1,
      })}
    >
      {buttons.map((button, index) => {
        switch (button.type) {
          case 'addToCart': // add to cart
            if (button?.shapeType === 'circle') {
              return (
                <div onClick={addToCart} key={button.type + index}>
                  <Picture
                    className={cn('w-[46px] cursor-pointer')}
                    source={
                      !products[0]?.availableForSale
                        ? button?.soldOutIcon ||
                          'https://cdn.shopify.com/s/files/1/0508/1815/4652/files/Union_5.svg?v=1709806696'
                        : button?.addCartIcon ||
                          'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/Frame_2_69904f2d-b0fe-44e2-8ee8-85cc302008a1.png?v=1701401151'
                    }
                  ></Picture>
                </div>
              )
            }
            if (button?.shapeType === 'combination') {
              return (
                <Button
                  key={button.type}
                  loading={loading}
                  onClick={addToCart}
                  class={cn(s.combinationWrap, 'combinationWrap relative', {
                    [s.soltBtn]: !products[0]?.availableForSale,
                    ['soltBtn']: !products[0]?.availableForSale,
                  })}
                >
                  <Picture
                    className={cn('mt-[-6px] w-[24px] cursor-pointer')}
                    source={
                      button?.addCartIcon ||
                      'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/Frame_ff2b9538-0277-4d06-b4d7-eb34430c1b88.png?v=1720150960'
                    }
                  ></Picture>
                  {products[0]?.availableForSale ? (
                    <span>
                      {buttonTxt ||
                        button?.txt ||
                        copy.addToCart ||
                        copy.add_to_Cart}
                    </span>
                  ) : (
                    <span>{copy?.soldOut || copy?.buy_out}</span>
                  )}
                </Button>
              )
            }
            return (
              <Button
                key={button.type}
                className={cn(
                  s.button,
                  cns.button,
                  button.theme,
                  s[button.theme || 'primary'],
                  {
                    [s.soltBtn]: !products[0]?.availableForSale,
                    ['soltBtn']: !products[0]?.availableForSale,
                  },
                  'buyAddBundle-right',
                  `buyAddBundle-right-${index}`,
                  squareBorder ? s?.squareBorder : ''
                )}
                loading={loading}
                onClick={addToCart}
                border={!!button.border}
                disabled={disabled}
                cns={button?.cns}
              >
                {buttonTxt || button?.txt || copy.addToCart || copy.add_to_Cart}
              </Button>
            )
          case 'buyAddBundle':
            return (
              <div
                key={button.type}
                className={cn(
                  'btnWrapper box-content grid h-[46px] w-full grid-cols-12 gap-0 overflow-hidden rounded-full border  xl:h-[40px]',
                  products[0]?.availableForSale
                    ? 'border-[#00db84]'
                    : 'border-[#ccc]',
                  gs.btnWrapper,
                  cns?.btnWrapper,
                  !products[0]?.availableForSale && gs.btnWrapperSolidout,
                  s.buyAddBundleBox,
                  'buyAddBundle-box',
                  squareBorder ? '!box-border !rounded-[0px] !border-[0px]' : ''
                )}
              >
                {button?.mainBtnType === 'link' ? (
                  <Button
                    variant="link"
                    key={button.type}
                    onClick={() => {
                      addLearnMoreGTM()
                    }}
                    href={getLink(button.link)}
                    target={blank ? '_blank' : '_self'}
                    rel="noopener noreferrer"
                    theme={button.theme}
                    className={cn(
                      s.button,
                      s.buyAddBundle,
                      cns.button,
                      button.theme,
                      'buyAddBundle',
                      squareBorder ? `${s?.squareBorder} !border-[0px]` : ''
                    )}
                    border={!!button.border}
                    cns={button?.cns}
                  >
                    {button?.txt || copy?.learnMore || copy?.learn_more}
                  </Button>
                ) : (
                  <Button
                    className={cn(
                      s.button,
                      s.buyAddBundle,
                      cns.button,
                      button.theme,
                      cns.buyAddBundle,
                      'buyAddBundle',
                      squareBorder ? `${s?.squareBorder} !border-[0px]` : ''
                    )}
                    border={!!button.border}
                    loading={loading}
                    onClick={buyNow}
                    disabled={!products[0]?.availableForSale || disabled}
                  >
                    {products[0]?.availableForSale ? (
                      <span>{copy?.buyNow || copy?.buy_now}</span>
                    ) : (
                      <span>{copy?.soldOut || copy?.buy_out}</span>
                    )}
                  </Button>
                )}
                <div
                  className={cn(
                    'buyAddBundle-cart col-span-3 flex h-full cursor-pointer items-center justify-center',
                    `${
                      !products[0]?.availableForSale || disabled
                        ? ' pointer-events-none cursor-not-allowed opacity-50'
                        : ''
                    }`,
                    s?.buyAddBundleCart,
                    squareBorder && (!products[0]?.availableForSale || disabled)
                      ? '!border-[#ccc]'
                      : ''
                  )}
                  loading={loading}
                  onClick={addToCart}
                >
                  <Picture
                    className={cn(' w-[24px]')}
                    source={
                      !products[0]?.availableForSale
                        ? button?.soldOutIcon ||
                          'https://cdn.shopify.com/s/files/1/0508/1815/4652/files/Union_5.svg?v=1709806696'
                        : button?.addCartIcon ||
                          'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/Frame_2_69904f2d-b0fe-44e2-8ee8-85cc302008a1.png?v=1701401151'
                    }
                  ></Picture>
                </div>
              </div>
            )
          case 'buyNow': // buy now
            return (
              <>
                {button?.variant ? (
                  <ButtonV2
                    key={button.type}
                    className={cn(button?.variant, cns.buttonV2)}
                    variant={button?.variant}
                    loading={loading}
                    onClick={buyNow}
                    disabled={!products[0]?.availableForSale || disabled}
                  >
                    {products[0]?.availableForSale ? (
                      <span>
                        {button?.txt || copy?.buyNow || copy?.buy_now}
                      </span>
                    ) : (
                      <span>{copy?.soldOut || copy?.buy_out}</span>
                    )}
                  </ButtonV2>
                ) : (
                  <Button
                    key={button.type}
                    className={cn(
                      s.button,
                      s[button.theme || 'primary'],
                      cns.button,
                      button.theme,
                      'buyAddBundle-right',
                      `buyAddBundle-right-${index}`,
                      squareBorder ? s?.squareBorder : ''
                    )}
                    border={!!button.border}
                    loading={loading}
                    onClick={buyNow}
                    disabled={!products[0]?.availableForSale || disabled}
                  >
                    {products[0]?.availableForSale ? (
                      <span>
                        {copy?.buyNow || copy?.buy_now || button?.txt}
                      </span>
                    ) : (
                      <span>{copy?.soldOut || copy?.buy_out}</span>
                    )}
                  </Button>
                )}
              </>
            )
          case 'amazonLink':
            const link =
              button?.link ||
              products?.[0]?.variants?.[0]?.metafields?.infos?.amazonLink
            if (!link) return null
            const chunkedLink = link?.split('/')
            const asin = chunkedLink?.[chunkedLink?.length - 1]
            return (
              <Button
                key={button.type}
                variant="link"
                target="_blank"
                rel="noopener noreferrer"
                href={`${link}?${getAmazonLinkParams(
                  asin,
                  copy.adCampaign,
                  router.locale
                )}`}
                onClick={() => handleBuried(products?.[0]?.sku)}
                theme={button.theme}
                className={cn(
                  s.button,
                  s[button.theme],
                  cns.button,
                  button.theme,
                  squareBorder ? s?.squareBorder : ''
                )}
              >
                {copy?.buyOnAmazon || button?.txt}
              </Button>
            )
          case 'comingSoon':
            return (
              <Button
                variant="link"
                key={button.type}
                theme={button.theme}
                className={cn(
                  s.button,
                  cns.button,
                  button.theme,
                  'buyAddBundle-right',
                  `buyAddBundle-right-${index}`,
                  squareBorder ? s?.squareBorder : ''
                )}
                border={!!button.border}
              >
                {copy.comingSoon}
              </Button>
            )
          default: // learn more
            return solixButton ? (
              <SolixButton
                variant="link"
                key={'SolixButton' + button.type}
                onClick={() => {
                  addLearnMoreGTM()
                }}
                href={custom_link || getLink(button.link)}
                target={blank && '_blank'}
                rel="noopener noreferrer"
                cns={button?.cns}
                className={cn('!max-w-[none]', button?.custom_height)}
              >
                {/* <a
                  href={getLink(button.link)}
                  target={blank && '_blank'}
                  rel="noopener noreferrer"
                  className="block h-full w-full"
                >
                  {button?.txt || copy?.learnMore || copy?.learn_more}
                </a> */}
                {button?.txt || copy?.learnMore || copy?.learn_more}
              </SolixButton>
            ) : solixButtonBorder ? (
              <a
                onClick={() => {
                  addLearnMoreGTM()
                }}
                href={custom_link || getLink(button.link)}
                key={button.type}
                className={cn(s?.solixButtonBorder)}
              >
                <Button className="text-linear-div">
                  <span className="text-linear">
                    {button?.txt || copy?.learnMore || copy?.learn_more}
                  </span>
                </Button>
              </a>
            ) : (
              <Button
                variant="link"
                key={button.type}
                onClick={(e) => {
                  addLearnMoreGTM()
                  if (typeof button?.btnCall == 'function') {
                    e?.preventDefault()
                    button?.btnCall()
                  }
                }}
                href={custom_link || getLink(button.link)}
                target={blank && '_blank'}
                rel="noopener noreferrer"
                theme={button.theme}
                className={cn(
                  s.button,
                  s[button.theme || 'primary'],
                  cns.button,
                  button.theme,
                  'buyAddBundle-right',
                  `buyAddBundle-right-${index}`,
                  squareBorder ? s?.squareBorder : ''
                )}
                border={!!button.border}
                cns={button?.cns}
              >
                {button?.txt || copy?.learnMore || copy?.learn_more}
              </Button>
            )
        }
      })}
    </div>
  )
}

export default Cta

export const buyNowGTM = ({
  products,
  router,
  info,
  gtmParam,
  combine_variant = [],
  nameMatch = [],
  ref,
}) => {
  const product = products[0]
  const skus = products?.map((product) => product?.sku)?.join(',')
  const totalPrice = products?.reduce(
    (prev, product) => new Decimal(prev || 0).plus(product?.price || 0),
    0
  )
  let combinePrice = 0
  if (combine_variant && combine_variant?.length > 0) {
    combinePrice = combine_variant?.reduce((prev, item) => {
      return new Decimal(prev || 0).plus(
        item?.variant?.availableForSale ? item?.variant?.price : 0
      )
    }, 0)
  }
  pageGTMEvent({
    event: 'eeEvent',
    eventCategory: 'EnhancedEcommerce',
    eventAction: 'BuyNow', // 加购则传Added Product
    eventLabel: skus,
    nonInteraction: false,
    ecommerce: {
      currencyCode: product.currencyCode, // 填写对应货币 USD EUR...
      add: {
        products: products?.map((product) => ({
          name: product?.title, // 对应下方截图的product name
          id: product?.sku, // 对应产品SKU
          category: '', // 留空
          categoryName: product?.category, // 对应下方截图的product cat...
          brand: product?.brand, // 对应下方截图的product brand
          variant: product?.variantName, // 对应下方截图的product variant
          price: combinePrice || totalPrice, // 对应下方截图的product price
          quantity: product?.quantity || 1, // 对应下方截图的product quantity
        })),
      },
    },
  })
  pageGTMEvent({
    event: 'ga4Event',
    event_name: 'begin_checkout',
    event_parameters: {
      info,
      position: nameMatch?.[1] || gtmParam?.itemListName,
      page_group: getGtmGroup({
        query: router.query,
        asPath: router.asPath,
        ref,
      }),
      currency: product?.currencyCode,
      value: totalPrice, //折扣后订单总价
      items: products?.map((product) => ({
        item_id: product?.sku,
        item_name: product?.title,
        item_brand: product?.brand,
        item_category: product?.category,
        item_variant: product?.variantName,
        price: combinePrice || totalPrice, //产品单价
        quantity: product?.quantity || 1,
      })),
    },
  })
}

export const addToCartGTM = ({
  products,
  router,
  gtmParam,
  info,
  combine_variant = [],
  nameMatch = [],
  ref,
}) => {
  const product = products[0]
  const skus = products?.map((product) => product?.sku)?.join(',')
  const totalPrice = products?.reduce(
    (prev, product) => new Decimal(prev || 0).plus(product?.price || 0),
    0
  )
  let combinePrice = 0
  if (combine_variant && combine_variant?.length > 0) {
    combinePrice = combine_variant?.reduce((prev, item) => {
      return new Decimal(prev || 0).plus(
        item?.variant?.availableForSale ? item?.variant?.price : 0
      )
    }, 0)
  }
  pageGTMEvent({
    event: 'eeEvent',
    eventCategory: 'EnhancedEcommerce',
    eventAction: 'Added Product', // 加购则传Added Product
    eventLabel: skus,
    nonInteraction: false,
    ecommerce: {
      currencyCode: product?.currencyCode, // 填写对应货币 USD EUR...
      add: {
        products: products?.map((product) => ({
          name: product?.title, // 对应下方截图的product name
          id: product?.sku, // 对应产品SKU
          category: '', // 留空
          categoryName: product?.category, // 对应下方截图的product cat...
          brand: product?.brand, // 对应下方截图的product brand
          variant: product?.variantName, // 对应下方截图的product variant
          price: combinePrice || totalPrice, // 对应下方截图的product price
          quantity: product?.quantity || 1, // 对应下方截图的product quantity
        })),
      },
    },
  })
  pageGTMEvent({
    event: 'ga4Event',
    event_name: 'add_to_cart',
    event_parameters: {
      info,
      page_group: getGtmGroup({
        query: router.query,
        asPath: router.asPath,
        ref,
      }),
      currency: product?.currencyCode,
      value: combinePrice || totalPrice, //折扣后订单总价
      position: nameMatch?.[1] || gtmParam?.itemListName || '', //模块名字
      items: products?.map((product) => ({
        item_id: product?.sku,
        item_name: product?.title,
        item_brand: product?.brand,
        item_category: product?.category,
        item_variant: product?.variantName,
        price: product?.price, //产品单价
        quantity: product?.quantity || 1,
      })),
    },
  })
  if (window.fbq) {
    fbq(
      'track',
      'AddToCart',
      // begin required parameter object
      {
        value: combinePrice || totalPrice,
        num_items: 1,
        currency: product?.currencyCode,
        content_name: product?.title, // 产品内容的名字
        content_type: 'product_group',
        content_ids: String(product?.variantId), // 产品的varaint id
        content_category: GTMtagsToCollection(
          product?.variantTags || product?.tags
        ), // 产品分类
      }
      // end required parameter object
    )
  }
}

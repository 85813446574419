import cn from 'classnames'
import React, { useRef } from 'react'
import mergeRefs from 'react-merge-refs'
import styles from './Button.module.css'

const ButtonV2 = ({
  className,
  variant = '',
  children,
  active,
  width,
  loading = false,
  disabled = false,
  style = {},
  Component = 'button',
  s: gs = {},
  buttonRef = null,
  ...rest
}) => {
  const ref = useRef < typeof Component > null
  const s = { ...styles, ...gs }
  const rootClassName = cn(
    s.root,
    {
      [s.disabled]: disabled,
      [s.loading]: loading,
      [s.brandBorder]: variant === 'brandBorder', // 品牌色边框
      [s.gradientBg]: variant === 'gradientBg', // 渐变底色
      [s.solixGradientBg]: variant === 'solixGradientBg',
      [s.blackBg]: variant === 'blackBg', // 黑色底色
      [s.blackBorder]: variant === 'blackBorder', // 黑色边框
    },
    className
  )

  return (
    <Component
      aria-pressed={active}
      data-variant={variant}
      // ref={mergeRefs([ref, buttonRef])}
      className={rootClassName}
      disabled={disabled || loading}
      tabIndex={'onClick' in rest ? '0' : '-1'}
      role={'onClick' in rest ? 'button' : ''}
      onKeyPress={
        'onClick' in rest
          ? (e) => {
              if (e.key == 'Enter') {
                rest.onClick && rest.onClick(e)
              }
            }
          : () => {}
      }
      style={{
        width,
        ...style,
      }}
      {...rest}
    >
      {loading ? (
        <div className={s.loadingDots}>
          <LoadingDots />
        </div>
      ) : (
        children
      )}
    </Component>
  )
}

export default ButtonV2

const LoadingDots = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  )
}
